<template>
  <div class="columns login_grid_height m-0">
    <div class="column is-6 left-section">
      <div class="welcome-carousel-container">
        <Carousel />
      </div>
    </div>
    <div class="column is-6 overlay-bg auth-form-container">
      <div class="column is-6 login-form">
        <img class="company-logo" src="/images/rise_logo_red.png" alt="" />
        <p class="main-text is-size-2">Create Password</p>
        <p class="child-text mb-4">
          Your identity has been verified. Set your new password.
        </p>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(createPassword)"
            class="form-reset-pwd"
          >
            <ValidationProvider
              name="password"
              v-slot="{ errors }"
              rules="required"
            >
              <b-field
                label="Password"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  class="input-field password-eye"
                  type="password"
                  placeholder="Password"
                  required
                  password-reveal
                  v-model="password"
                ></b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              name="confirm"
              v-slot="{ errors }"
              rules="required|confirmed:password"
            >
              <b-field
                label="Re-Enter Password"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
                class="pdb"
              >
                <b-input
                  class="input-field password-eye"
                  type="Password"
                  placeholder="password"
                  required
                  password-reveal
                  v-model="confirmPassword"
                ></b-input>
              </b-field>
            </ValidationProvider>
            <b-field class="is-flex is-justify-content-center login-cta mb-1">
              <b-button class="is-fullwidth" native-type="submit">
                Create Password
              </b-button>
            </b-field>
          </form>
        </ValidationObserver>
        <div class="text-footers">
          <p>
            PT Roketin Kreatif Teknologi. Copyright
            {{ new Date().getFullYear() }}. RISE {{ appVersion }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { showToast } from '@/services/util'
import { appVersion } from '@/version'
import Carousel from '../../components/Carousel.vue'
import ApiService from '@/services/common/api.service'

export default {
  components: { Carousel },
  data() {
    return {
      password: '',
      confirmPassword: '',
      isLoading: false,
      isSuccess: false,
    }
  },
  computed: {
    appVersion: () => appVersion,
  },
  methods: {
    showToast(message, event) {
      this.$buefy.toast.open({
        duration: 5000,
        message: ` <b>${message}</b>`,
        position: 'is-top',
        type: event === 'failed' ? 'is-danger' : 'is-success',
      })
    },
    async createPassword() {
      this.isLoading = true
      try {
        let response = await ApiService.put(
          '/api/space-roketin/reset-password',
          {
            forgotPasswordToken: this.$route.params.token,
            newPassword: this.confirmPassword,
            newPasswordConfirmation: this.confirmPassword,
          }
        )
        await this.$router.push('/auth/login')
        this.$swal({
          icon: 'success',
          titleText: 'Password Updated',
          text: 'Your password has been updated!',
          confirmButtonText: 'Complete',
          customClass: {
            popup: 'swal-forgot2',
            confirmButton: 'button is-success mt17',
          },
        })
        this.showToast(response.data.message, 'success')
      } catch (err) {
        this.showToast(err.response.data.meta.message, 'failed')
      }
      this.isLoading = false
    },
  },
}
</script>
